import React from "react";
import classes from "./About.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareTwitter,
  faInstagram,
  faInstagramSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import backgroundImage from "../../assets/images/photos/Draft Taps.jpg";

import { addCocktailVariants } from "../../config/config";
import { motion } from "framer-motion";

const About = ({ className }) => {
  const classesList = `${classes.main} ${className}`;
  return (
    <motion.div
      className={classesList}
      variants={addCocktailVariants}
      // initial="hidden"
      // animate="visible"
      // exit="exit"
    >
      <div className={classes.left}>
        <img
          className={classes.background}
          src={backgroundImage}
          alt="background"
        />
      </div>
      <div className={classes.right}>
        <div className={classes.bio}>
          <h3 className={classes.bioText}>
            This place is more than just a bar, it is a fun community place, it
            is our home.
          </h3>
          <h3 className={classes.bioText}>
            We serve great people, then serve them cocktails & food. We are
            truly inspired by perfectionism in the food, beverage & service that
            we provide.
          </h3>
          <h3 className={classes.bioText3}>Welcome to Overpressure Club.</h3>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
