import React from "react";
import classes from "./Closed.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareTwitter,
  faInstagram,
  faInstagramSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import backgroundImage from "../../assets/images/photos/Full Room 2.jpg";

import { addCocktailVariants } from "../../config/config";
import { motion } from "framer-motion";

const Closed = ({ className }) => {
  const classesList = `${classes.main} ${className}`;
  return (
    <motion.div
      className={classesList}
      variants={addCocktailVariants}
      // initial="hidden"
      // animate="visible"
      // exit="exit"
    >
      {/* <div className={classes.left}>
        <img
          className={classes.background}
          src={backgroundImage}
          alt="background"
        />
      </div> */}
      <div className={classes.right}>
        <img
          className={classes.background}
          src={backgroundImage}
          alt="background"
        />
        <div className={classes.bio}>
          <h1 className={classes.bioText}>
            Overpressure Club is Temporarily Closed.
          </h1>
          <h3 className={classes.bioText}>
            Thank you for your support and memories at OPC. While this chapter
            has come to a close, the concept will return one day in a new
            location.
          </h3>
          <h3 className={classes.bioText}>
            In the meantime, we’re excited to introduce our new venture:
          </h3>
          <h3 className={classes.bioText}>Gochu Libre Kantina!</h3>
          <h3 className={classes.bioText}>
            A vibrant, casual spot where Mexican and Korean flavors come
            together, featuring incredible margaritas and shareable plates.
          </h3>
          <h3 className={classes.bioText}>
            Join us at
            <a target="_blank" href="https://www.gochulibre.com" className={classes.bold}>
              {" "}
              gochulibre.com{" "}
            </a>
            and experience something new. We can’t wait to see you there!
          </h3>
        </div>
      </div>
    </motion.div>
  );
};

export default Closed;
